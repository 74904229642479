import {DataProvider, Identifier} from 'ra-core'
import * as t from 'io-ts'
import {pipe} from 'fp-ts/lib/function'
import {getAndDecode, postAndDecode} from 'shared'
import {TE} from 'shared/dist/fp-ts-imp'
import * as T from 'fp-ts/Task'

const currentHost = process.env.REACT_APP_BACKEND_URL

const UserC = t.type(
  {
    id: t.string,
    accessCVT: t.string,
    email: t.string,
    fundraiserName: t.string,
    name: t.string,
    phoneNbr: t.string,
    participantImageUrl: t.string,
  },
  'UsersC',
)
const ListUsersResponseC = t.readonlyArray(UserC,
  'GetUsersResponseC',
)
const GetUserResponseC = UserC

const UpdateUserResponse = t.type(
  {
    success: t.boolean,
  },
  'UpdateUserResponse',
)

const dataProvider: DataProvider = {
  getList: (resource, params) =>
    pipe(
      getAndDecode(ListUsersResponseC)(`${currentHost}/app/users`, {
        credentials: 'include',
      }),
      TE.map(us => {
        console.log(us)
        return us
      }),
      TE.map(us => us.concat()),
      TE.fold(
        () => T.of([]),
        us => T.of(us),
      ),
      T.map(us => ({data: us as any[], total: us.length})),
    )(),

  getOne: (resource, params) =>
    pipe(
      getAndDecode(GetUserResponseC)(`${currentHost}/app/user/${params?.id}`, {
        credentials: 'include',
      }),
      TE.mapLeft(err => console.log(JSON.stringify(err))),
      TE.fold(
        () => T.of({}),
        u => T.of(u),
      ),
      T.map(u => ({data: u as any})),
    )(),

  getMany: (resource, params) => Promise.reject(),

  getManyReference: (resource, params) => Promise.reject(),

  update: (resource, {id, data}: {id: Identifier; data: any}) => pipe(
      postAndDecode(UpdateUserResponse)(`${currentHost}/app/update-userx`, {
        body: JSON.stringify({id, user: data}),
        credentials: 'include',
      }),
      TE.map(r => {
        console.log(r)
        return r
      }),
      // TE.mapLeft(() => TE.throwError(new Error('like everything else ra sucks'))),
      TE.fold(
        () => T.of(data),
        () => T.of(data),
      ),
      T.map(() => ({data})),
    )(),

  // httpClient(`${apiUrl}/${resource}/${params.id}`, {
  //   method: 'PUT',
  //   body: JSON.stringify(params.data),
  // }).then(({json}) => ({data: json})),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) => Promise.reject(),

  create: (resource, params) => Promise.reject(),

  delete: (resource, params) => Promise.reject(),

  deleteMany: (resource, params) => Promise.reject(),
}

export default dataProvider
