import {
  Admin,
  Resource,
  Datagrid,
  EmailField,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
} from 'react-admin'
import authProvider from './authProvider'
import dataProvider from './dataProvider'

const UserList = () => (
  <List>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phoneNbr" />
      <EditButton />
    </Datagrid>
  </List>
)

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)
const UserEdit = () => (
  <Edit>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phoneNbr" />
    </SimpleForm>
  </Edit>
)

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    {/* <Resource name="posts" list={PostList} edit={EditGuesser} /> */}
    <Resource name="users" list={UserList} edit={UserEdit} />
  </Admin>
)
export default App
