import {constVoid, pipe} from 'fp-ts/lib/function'
import {postAndDecode} from 'shared'
import {E, TE} from 'shared/dist/fp-ts-imp'
import * as t from 'io-ts'
import * as Task from 'fp-ts/Task'
import {AuthProvider} from 'react-admin'

const currentHost = process.env.REACT_APP_BACKEND_URL

const AdminLoginResponseC = t.type(
  {
    success: t.boolean,
  },
  'AdminLoginResponseC',
)

const AdminLogoutResponseC = t.type(
  {
    logout: t.boolean,
  },
  'AdminLogoutResponseC',
)

const authProvider: AuthProvider = {
  login: ({username, password}: {username: string; password: string}) =>
    pipe(
      postAndDecode(AdminLoginResponseC)(`${currentHost}/auth/admin-login`, {
        body: JSON.stringify({
          userName: username,
          password,
        }),
        credentials: 'include',
      }),
      TE.map(r => {
        console.log(r)
        return r
      }),
      TE.mapLeft(err => {
        console.log(`${currentHost}/sms/admin-login`)
        const message =
          err._tag === 'HTTP_ERROR'
            ? `${err.status} - ${err.message}`
            : JSON.stringify(err)
        console.log(message)
        return Promise.reject(message)
      }),
    )(),

  logout: () =>
    pipe(
      postAndDecode(AdminLogoutResponseC)(`${currentHost}/auth/admin-logout`, {
        credentials: 'include',
      }),
      TE.map(({logout}) => logout),
      TE.mapLeft(err => {
        console.log(`${currentHost}/sms/admin-logout`)
        const message =
          err._tag === 'HTTP_ERROR'
            ? `${err.status} - ${err.message}`
            : JSON.stringify(err)
        console.log(message)
      }),
      TE.fold(
        () => Task.of(constVoid()),
        () => Task.of(constVoid()),
      ),
    )(),

  checkAuth: async () => {
    const res = await pipe(
      postAndDecode(AdminLoginResponseC)(
        `${currentHost}/auth/validate-admin-token`,
        {credentials: 'include'},
      ),
      TE.map(({success}) => success),
      TE.mapLeft(err => {
        console.log(`${currentHost}/sms/admin-logout`)
        const message =
          err._tag === 'HTTP_ERROR'
            ? `${err.status} - ${err.message}`
            : JSON.stringify(err)
        console.log(message)
      }),
      // TE.fold(
      //   () => Task.of(constVoid()),
      //   () => Task.of(constVoid()),
      // ),
    )()
    return E.isLeft(res) ? Promise.reject() : Promise.resolve()
  },
  checkError: () => {
    // const status = error.status
    // if (status === 401 || status === 403) {
    //   localStorage.removeItem('username')
    // return Promise.reject()
    // }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  getIdentity: () =>
    Promise.resolve({
      id: 'admin',
      fullName: 'VD Administrator',
    }),
  getPermissions: () => Promise.resolve(''),
}

export default authProvider
